<template>
    <div class="timelineView">
        <Header/>
        <Timeline/>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Timeline from '@/components/timeline/Timeline.vue'

    export default {
      name: 'TimelineView',
      components: {
        Header,
        Timeline
      }
    }
</script>
