<template>
  <div class="text-center pt-3">
    <h3>Calculadora de Prazos Processuais</h3>
  </div>
</template>

<script>
</script>

<style scoped>
</style>
