<template>
    <main class="container">

        <div class="form-group row justify-content-center pt-4">
            <label for="datepicker" class="col-xs-12 col-form-label">Data Inicial</label>
            <div class="input-group date col-lg-2 col-md-3 col-sm-4 col-xs-12" style="min-width: 180px;"
                 data-provide="datepicker" id="datepicker">
                <input type="text" class="form-control" ref="startDate">
                <div class="input-group-addon pl-1">
                    <span class="fas fa-calendar fa-2x"></span>
                </div>
            </div>
        </div>

        <div class="form-group row justify-content-center pt-2">
            <label for="duration" class="col-xs-12 col-form-label">Duração</label>
            <div class="input-group date col-lg-1 col-md-1 col-sm-1 col-xs-12" style="min-width: 90px;">
                <input type="number" class="form-control" ref="duration" min="0" value="10"
                       onfocus="this.select(); this.setSelectionRange(0,99);" id="duration">
            </div>
            <label for="duration" class="col-xs-12 col-form-label">dias</label>
        </div>

        <div class="row justify-content-center ml-2">
            <div>
                <input class="form-check-input" type="checkbox" id="excludeJusticeHolidays"
                       ref="excludeJusticeHolidays">
                <label class="form-check-label" for="excludeJusticeHolidays">Corre em férias</label>
            </div>
        </div>

        <div class="row justify-content-center pt-3">
            <button type="submit" class="btn btn-primary" @click="onCalculateDateClick()">Calcular</button>
        </div>

        <div class="row justify-content-center pt-5">
            <button type="submit" class="btn btn-info" @click="onHolidaysClick()">Consultar Férias</button>
        </div>
    </main>
</template>

<script>
export default {
      mounted () {
        $('#datepicker').datepicker({
        format: 'dd-mm-yyyy',
        weekStart: 1,
        daysOfWeekHighlighted: "6,0",
        autoclose: true,
        todayHighlight: true,
        language: "pt"
    });
    $('#datepicker').datepicker("setDate", new Date());
      },
      methods: {
            onHolidaysClick() {
            this.$router.push('/ferias/'+ new Date().getFullYear());
        },
         onCalculateDateClick() {
             if (this.$refs.excludeJusticeHolidays.checked){
            this.$router.push({ path: '/resultado', query: { data_inicial: this.$refs.startDate.value, duracao:  this.$refs.duration.value, corre_em_ferias:  this.$refs.excludeJusticeHolidays.checked}});
         }else{
            this.$router.push({ path: '/resultado', query: { data_inicial: this.$refs.startDate.value, duracao:  this.$refs.duration.value}});
         }
        }
      }
    };
</script>


<style scoped>
</style>
